.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
}

.popup-content {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 20px;
    max-width: 600px;
}

.question {
    display: inline-block;
}

.buttons {
    text-align: center;
}
